import { template as template_70ba18e8b49f4cc9a7b1ee25f8476195 } from "@ember/template-compiler";
const FKControlMenuContainer = template_70ba18e8b49f4cc9a7b1ee25f8476195(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
